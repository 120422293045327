// Modal Video Sobre e Coordenador PDPs  //
if ($('#ModalVideoCoord').length > 0) {
  var modalVideoCoord = document.getElementById("ModalVideoCoord");
  var btnVideoCoord = document.getElementById("scconteudo-tp-coord-blocoinfo-video-btn");
  var btnVideoCoordMobile = document.getElementById("scconteudo-tp-coord-blocoinfo-video-btn-2");
  var spanVideoCoord = document.getElementsByClassName("scconteudo-tp-coord-blocoinfo-video-mconteudo-fechar")[0];
  var bodystop = document.getElementsByClassName("single")[0];

  if (btnVideoCoord) {
    btnVideoCoord.onclick = function () {
      modalVideoCoord.style.display = "block";
      bodystop.style.position = "fixed";
      bodystop.style.height = "100vh";
    }
  }

  if (btnVideoCoordMobile) {
    btnVideoCoordMobile.onclick = function () {
      modalVideoCoord.style.display = "block";
      bodystop.style.position = "fixed";
      bodystop.style.height = "100vh";
    }
  }

  if (spanVideoCoord) {
    spanVideoCoord.onclick = function () {
      modalVideoCoord.style.display = "none";
      bodystop.style.position = "initial";
      bodystop.style.height = "initial";
    }
  }

  window.onclick = function (event) {
    if (event.target == modalVideoCoord) {
      modalVideoCoord.style.display = "none";
      bodystop.style.position = "initial";
      bodystop.style.height = "initial";
    }
  }

  $('#scconteudo-tp-coord-blocoinfo-video-btn').click(function (e) {
    e.preventDefault();
    $('body').addClass('blockBody');
  });

  $('#scconteudo-tp-coord-blocoinfo-video-btn-2').click(function (e) {
    e.preventDefault();
    $('body').addClass('blockBody');
  });

  $('#scconteudo-tp-coord-blocoinfo-video-btnmobile').click(function (e) {
    e.preventDefault();
    $('body').addClass('blockBody');
  });

  $('.scconteudo-tp-coord-blocoinfo-video-mconteudo-fechar').click(function (e) {
    e.preventDefault();
    $('body').removeClass('blockBody');
  });

}
// END Modal Video Sobre e Coordenador PDPs